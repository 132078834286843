import React from "react";
import { NavigationDots, SocialMedia } from "../components";

const AppWrap = (Component, idName, classNames) =>
  function HOC() {
    return (
      <div id={idName} className={`app__container ${classNames}`}>
        <SocialMedia />
        <div className="app__wrapper app__flex">
          <Component />
          <div className="copyright">
            <p></p>
            <p className="p-text">&copy; 2016-{new Date().getFullYear()}</p>
            <p className="p-text">All rights reserved</p>
            <p className="p-text">
              <a
                className=""
                href="https://www.ennzu.com/"
                style={{ textDecoration: "none" }}
              >
                Developed with&nbsp;
              </a>
              <a
                className="p-text"
                href="https://www.ennzu.com/"
                style={{ color: "#E21C21", textDecoration: "none" }}
              >
                ❤
              </a>
              <a
                className=""
                href="https://www.ennzu.com/"
                style={{ textDecoration: "none" }}
              >
                &nbsp;by ennzu&nbsp;
              </a>
              <a
                className="p-text"
                href="https://www.ennzu.com/"
                style={{ color: "#4682B4", textDecoration: "none" }}
              >
                ⧫
              </a>
            </p>
          </div>
        </div>
        <NavigationDots active={idName} />
      </div>
    );
  };

export default AppWrap;
