import email from "../assets/email.png";
import git from "../assets/git.png";
import notion from "../assets/notion.png";
import react from "../assets/react.png";
import profile from "../assets/profile.png";
import circle from "../assets/circle.svg";
import logo from "../assets/logo.png";
import transparent from "../assets/transparent.png";

export default {
  notion,
  git,
  react,
  profile,
  circle,
  logo,
  email,
  transparent,
};
