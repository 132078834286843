import React from "react";
import { BsTwitter, BsLinkedin, BsDiscord } from "react-icons/bs";

const SocialMedia = () => (
  <div className="app__social">
    <a
      href="https://twitter.com/ennzudev"
      target="_blank"
      rel="noopener noreferrer"
    >
      <div>
        <BsTwitter />
      </div>
    </a>
    <a
      href="https://linkedin.com/in/ennzu"
      target="_blank"
      rel="noopener noreferrer"
    >
      <div>
        <BsLinkedin />
      </div>
    </a>
    <a
      href="https://discordapp.com/users/689197980074639388"
      target="_blank"
      rel="noopener noreferrer"
    >
      <div>
        <BsDiscord />
      </div>
    </a>
  </div>
);

export default SocialMedia;
